/*
 * Bingo Buttons
 */
.BingoButton {
  border: none;
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: rgb(75,0,130);
  cursor: pointer;
  font-weight: bold;
}

.BingoClicked {
  background-color: rgb(149, 125, 149);
  color: rgb(248, 200, 220);
}

@media screen and (min-width: 701px) {
  .BingoButton {
    height: 70px;
    font-size: 12px;
    margin: 2px;
    width: 100px;
  }

  .BingoButton:hover {
    background-color: rgb(149, 125, 149);
    box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.3);
    color: rgb(248, 200, 220);
  }

  .BingoClicked:hover {
    background-color: rgb(207, 199, 218);
    box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.3);
    color: rgb(75,0,130);
  }
}

@media screen and (max-width: 700px) {
  .BingoButton {
    height: 45px;
    font-size: 8px;
    margin: 4px;
    padding: 4px;
    width: 60px;
  }
}

/*
 * Event buttons settings
 */
.EventButton {
  position: relative;
  border: none;
  border-radius: 0 8px 8px 0;
  font-size: 12px;
  height: 16px;
  left: -11px;
  overflow: hidden;
  white-space: nowrap;
}

.EventButton.WrapEvent {
  border-radius: 0;
}

@media screen and (min-width: 770px) and (max-width: 1200px) {
  /* Set event buttons specifics if needed */
}

@media  screen and (max-width: 700px) {
  .EventButton {
    border-radius: none;
    height: 5px;
    position: static;
  }
}

.InuKag {
  background-color: rgba(255, 0, 0, .65);
}

.MirSan {
  background-color: rgba(75,0,130,.65);
}

.TogIza {
  background-color: rgba(0, 0, 0,.35);
}

.SessKagu {
  background-color: rgba(255, 165, 0, .65);
}

.SessKag {
  background-color: rgba(0, 0, 255, .65);
}

.KogKag {
  background-color: rgba(4, 128, 4, .65);
}

.Other {
  background-color: rgba(230, 225, 0, .65);
}

.Multi-ship {
  background: linear-gradient(45deg, rgba(255,0,0,.65), rgba(255,165,0,.65) , rgba(255,255,0,.65), rgba(0,128,0,.65), rgba(0,0,255,.65), rgba(75,0,130,.65));
}

.None {
  display: none;
}

/*
 * Discord Sign in link css
 */
.Discord {
  background-color: rgb(65,74,232);
  border: none;
  border-radius: 4px;
  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  margin: 4px;
  padding: 8px;
  width: 300px;
}

.Discord:hover {
  background-color: white;
  box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.3);
  color: rgb(65,74,232);
}

.thumbnail {
  position: absolute;
  transform: scale(10%, 10%);
  transform-origin: top left;
  left: 25px;
  top: 15px;
}

.PopoverSpacing {
  white-space: pre-wrap;
}
