html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

* { box-sizing: border-box; }

body {
  background-color: rgb(75,0,130);
  height: 100vh;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-link.active {
  background-color: white !important;
  color: rgb(75,0,130) !important;
}

.LibraryBtn {
  color: white;
}

.LibraryBtn:focus {
  background-color: rgb(75,0,130);
  color: white;
}

.LibraryBtn:hover {
  background-color: rgb(149, 125, 149);
  color: rgb(248, 200, 220);
}

.CardSaveList {
  color: rgb(75,0,130);
}

.CardSaveList:focus {
  background-color: rgb(207, 199, 218);
  color: rgb(75,0,130);
}

.CardSaveList:hover {
  background-color: rgb(149, 125, 149);
  color: rgb(248, 200, 220);
}

.dropdown-menu {
  background: rgb(75,0,130);
  border-color: white;
  border-width: 1px;
}

.dropdown-divider {
  color: white;

}

/*
 * Animation frames
 * Defined here for use throughout entire site.
 * Done this way to get around the way React handles CSS
 */
 /*
 *  Walking frames
 */
.Kagome-walking-4 {
  transform: translate(-100px, -42px);
}
.Kagome-walking-3 {
  transform: translate(-68px, -42px);
}
.Kagome-walking-2 {
  transform: translate(-36px, -42px);
}
.Kagome-walking-1 {
  transform: translate(-4px, -42px);
}
.Inuyasha-walking-4 {
  transform: translate(-102px, -40px);
}
.Inuyasha-walking-3 {
  transform: translate(-70px, -40px);
}
.Inuyasha-walking-2 {
  transform: translate(-38px, -40px);
}
.Inuyasha-walking-1 {
  transform: translate(-6px, -40px);
}
.Sango-walking-4 {
  transform: translate(-367px, -42.75px);
}
.Sango-walking-3 {
  transform: translate(-335px, -42.75px);
}
.Sango-walking-2 {
  transform: translate(-303px, -42.75px);
}
.Sango-walking-1 {
  transform: translate(-271px, -42.75px);
}
.Miroku-walking-4 {
  transform: translate(-109px, -39.5px);
}
.Miroku-walking-3 {
  transform: translate(-77px, -39.5px);
}
.Miroku-walking-2 {
  transform: translate(-45px, -39.5px);
}
.Miroku-walking-1 {
  transform: translate(-13px, -39.5px);
}
.Shippo-walking-4 {
  transform: translate(-104px, -39px);
}
.Shippo-walking-3 {
  transform: translate(-72px, -39px);
}
.Shippo-walking-2 {
  transform: translate(-40px, -39px);
}
.Shippo-walking-1 {
  transform: translate(-8px, -39px);
}
.Kirara-walking-2 {
  transform: translate(-188px, -204px);
}
.Kirara-walking-1 {
  transform: translate(-135px, -204px);
}
.Kagura-walking-4 {
  transform: translate(-233px, -35px);
}
.Kagura-walking-3 {
  transform: translate(-201px, -35px);
}
.Kagura-walking-2 {
  transform: translate(-169px, -35px);
}
.Kagura-walking-1 {
  transform: translate(-137px, -35px);
}
.Kikyo-walking-4 {
  transform: translate(-83px, -70px);
}
.Kikyo-walking-3 {
  transform: translate(-59px, -70px);
}
.Kikyo-walking-2 {
  transform: translate(-35px, -70px);
}
.Kikyo-walking-1 {
  transform: translate(-11px, -70px);
}
.Koga-walking-4 {
  transform: translate(-175px, -5px);
}
.Koga-walking-3 {
  transform: translate(-151px, -5px);
}
.Koga-walking-2 {
  transform: translate(-127px, -5px);
}
.Koga-walking-1 {
  transform: translate(-103px, -5px);
}
.Sesshomaru-walking-4 {
  transform: translate(-102px, -40px);
}
.Sesshomaru-walking-3 {
  transform: translate(-70px, -40px);
}
.Sesshomaru-walking-2 {
  transform: translate(-38px, -40px);
}
.Sesshomaru-walking-1 {
  transform: translate(-6px, -40px);
}
.Jaken-walking-4 {
  transform: translate(-67px, -40px);
}
.Jaken-walking-3 {
  transform: translate(-51px, -40px);
}
.Jaken-walking-2 {
  transform: translate(-35px, -40px);
}
.Jaken-walking-1 {
  transform: translate(-6px, -40px);
}
.Naraku-walking-4 {
  transform: translate(-78px, -38px);
}
.Naraku-walking-3 {
  transform: translate(-54px, -38px);
}
.Naraku-walking-2 {
  transform: translate(-30px, -38px);
}
.Naraku-walking-1 {
  transform: translate(-6px, -38px);
}
.Yura-walking-4 {
  transform: translate(-104px, -40px);
}
.Yura-walking-3 {
  transform: translate(-72px, -40px);
}
.Yura-walking-2 {
  transform: translate(-40px, -40px);
}
.Yura-walking-1 {
  transform: translate(-8px, -40px);
}
.Kanna-walking-4 {
  transform: translate(-102px, -35px);
}
.Kanna-walking-3 {
  transform: translate(-70px, -35px);
}
.Kanna-walking-2 {
  transform: translate(-38px, -35px);
}
.Kanna-walking-1 {
  transform: translate(-6px, -35px);
}
.Urasue-walking-4 {
  transform: translate(-238px, -10px);
}
.Urasue-walking-3 {
  transform: translate(-207px, -10px);
}
.Urasue-walking-2 {
  transform: translate(-175px, -10px);
}
.Urasue-walking-1 {
  transform: translate(-143px, -10px);
}
.Kohaku-walking-4 {
  transform: translate(-109px, -41px);
}
.Kohaku-walking-3 {
  transform: translate(-77px, -41px);
}
.Kohaku-walking-2 {
  transform: translate(-45px, -41px);
}
.Kohaku-walking-1 {
  transform: translate(-13px, -41px);
}
.Kaede-walking-4 {
  transform: translate(-107px, -40px);
}
.Kaede-walking-3 {
  transform: translate(-75px, -40px);
}
.Kaede-walking-2 {
  transform: translate(-43px, -40px);
}
.Kaede-walking-1 {
  transform: translate(-11px, -40px);
}
