@font-face {
  font-family: 'Satisfy-Regular';
  src: local('MyFont'), url(../../components/assets/fonts/Satisfy-Regular.ttf) format('truetype');
}

.Welcome {
  color: white;
  font-family: Satisfy-Regular;
  font-size: 5vw;
  font-weight: bold;
  margin-top: 10vh;
  text-align: center;
}

.Flower {
  transform: rotate(10deg);
}

@media screen and (max-width: 900px) {
  .Welcome {
    font-size: 40px;
  }
}
