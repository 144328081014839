.App {
  position: relative;
}
/*
Old. Keeping for reference for now.
  overflow-x: auto;
  white-space: nowrap;
  height: 100vh;
  width: 100vw;
  position: relative;
*/
