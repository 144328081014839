@font-face {
  font-family: 'Satisfy-Regular';
  src: local('MyFont'), url(../../components/assets/fonts/Satisfy-Regular.ttf) format('truetype');
}

.login {
  position: absolute;
  text-decoration: none;
  width: 15rem;
}

/* pastel pink: rgb(248, 200, 220) */
.Heading {
  color: white;
  font-family: Satisfy-Regular;
  font-size: 4vw;
  font-weight: bold;
  margin-top: 10vh;
}

.Subheading {
  color: white;
  font-family: Satisfy-Regular;
  font-size: 2vw;
  font-weight: bold;
}

.Nonmember {
  color: white;
  font-size: 1vw;
}

@media screen and (max-width: 700px) {
  .Heading {
    font-size: 4vh;
  }

  .Subheading {
    font-size: 2vh;
  }

  .Nonmember {
    font-size: 3vw;
  }
}
