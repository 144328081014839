.FormHeader {
  color: white;
}

.FormBtn:hover {
  color:rgb(75, 0, 130);
}

.Day {
  border: 1px solid rgb(75, 0, 130);
  background-color: white;
  color: rgb(75, 0, 130);
  height: 95px;
  width: 136px;
}

.DayHeader {
  background-color: rgb(149, 125, 149);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: rgb(248, 200, 220);
  font-weight: bold;
  padding: 4px;
  text-align: center;
  height: 30px;
  width: 136px;
}

.CurrentDay {
  background-color: rgb(248, 200, 220);
}

.DateDisplay {
  color: white;
  font-weight: bold;
  font-size: 25px;
}

.Event {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

@media screen and (min-width: 770px) and (max-width: 1200px) {
  .Day {
    font-size: 15px;
    height: 60px;
    width: 81px;
  }

  .DayHeader {
    border-radius: 3px;
    font-size: 15px;
    padding: 2px;
    height: 27px;
    width: 81px;
  }
}

@media screen and (max-width: 769px) {
  .Day {
    font-size: 8px;
    height: 60px;
    width: 50px;
  }

  .DayHeader {
    border-radius: 2px;
    font-size: 8px;
    padding: 2px;
    height: 17px;
    width: 50px;
  }

  .DateDisplay {
    font-size: 13px;
  }
}

.NotCurrentMonth {
  background-color: #cfc7da;
  color: rgb(75,0,130);
}

.InuKag {
  background-color: rgb(255, 0, 0) !important;
}

.MirSan {
  background-color: rgba(75,0,130,.65) !important;
}

.TogIza {
  background-color: rgba(0, 0, 0,.35) !important;
}

.SessKagu {
  background-color: rgb(255, 165, 0) !important;
}

.SessKag {
  background-color: rgb(0, 0, 255) !important;
}

.KogKag {
  background-color: rgb(4, 128, 4) !important;
}

.Other {
  background-color: rgb(230, 225, 0) !important;
}

.Multi-ship {
  background: linear-gradient(45deg, red, orange , yellow, green, blue, rgba(75,0,130,.65));
}

.None {
  display: none;
}

.ModalSpacing {
  white-space: pre-wrap;
}

.PopoverSpacing {
  white-space: pre-line;
}
