.card {
  perspective: 100%;
}

.card.flipped > .cardButton {
  transform: rotateY(180deg);
}

.card,
.cardButton {
  position: relative; 
}

@media screen and (min-width: 1401px) {
  .card,
  .cardButton {
    width: 75px;
    height: 75px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .card,
  .cardButton {
    width: 60px;
    height: 60px;
  }
}
@media screen and (min-width: 701px) and (max-width: 1200px) {
  .card,
  .cardButton {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 700px) {
  .card,
  .cardButton {
    width: 45px;
    height: 45px;
  }
}

.cardButton {
  transition: transform .5s ease-in-out;

  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}

.cardBack {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
}
